<template>
  <div>
    <div class="header-text pb-3" data-cy="header-admin-system">
      System Administration
    </div>
    <div class="box-style mb-6">
      <div class="mb-2">
        <svg
          class="inline-block mr-2"
          id="icon-flag"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 16 16"
        >
          <path
            id="flag"
            d="M10.505,2.5c-1.535,0-2.916-1-5.06-1a6.936,6.936,0,0,0-2.523.474A1.5,1.5,0,1,0,.75,2.8V15.5a.5.5,0,0,0,.5.5h.5a.5.5,0,0,0,.5-.5V12.892A8.6,8.6,0,0,1,6.245,12c1.535,0,2.916,1,5.06,1a7.26,7.26,0,0,0,4.017-1.249A1.5,1.5,0,0,0,16,10.5V3a1.5,1.5,0,0,0-2.091-1.379A8.938,8.938,0,0,1,10.505,2.5Zm3.995,8a5.878,5.878,0,0,1-3.2,1c-1.873,0-3.188-1-5.06-1a10.719,10.719,0,0,0-3.995.75V4a5.878,5.878,0,0,1,3.2-1c1.873,0,3.188,1,5.06,1A10.685,10.685,0,0,0,14.5,3Z"
            transform="translate(0 0)"
          />
        </svg>
        <div class="thirdhead-text inline-block mb-4">Content Management</div>
      </div>
      <div class="report_box grid gap-8 grid-cols-3">
        <div v-if="!isSra()">
          <router-link v-if="!isSra()" to="/admin/sysadmin/practice-areas">
            <button
              class="button bg-white border border-cool-gray w-5/6 rounded-md py-2 mb-4"
            >
              Practice Areas
            </button>
          </router-link>
          <router-link v-if="!isSra()" to="/admin/sysadmin/experiences">
            <button
              class="button bg-white border border-cool-gray w-5/6 rounded-md py-2 mb-4"
            >
              Experiences
            </button>
          </router-link>
        </div>
        <div>
          <router-link to="/admin/sysadmin/employers">
            <button
              class="button bg-white border border-cool-gray w-5/6 rounded-md py-2 mb-4"
            >
              Employer Directory
            </button>
          </router-link>
          <router-link v-if="!isSra()" to="/admin/sysadmin/debrief-templates">
            <button
              class="button bg-white border border-cool-gray w-5/6 rounded-md py-2 mb-4"
            >
              Debrief Templates
            </button>
          </router-link>
        </div>
        <div>
          <router-link v-if="!isSra()" to="/admin/sysadmin/student-surveys">
            <button
              class="button bg-white border border-cool-gray w-5/6 rounded-md py-2 mb-4"
            >
              Student Surveys
            </button>
          </router-link>
          <router-link to="/admin/sysadmin/mentor-surveys">
            <button
              class="button bg-white border border-cool-gray w-5/6 rounded-md py-2 mb-4"
            >
              Mentor Surveys
            </button>
          </router-link>
          <router-link v-if="!isSra()" to="/admin/sysadmin/student-promotion">
            <button
              class="button bg-white border border-cool-gray w-5/6 rounded-md py-2 mb-4"
            >
              Student Promotion
            </button>
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="!isSra()" class="box-style mb-6">
      <div class="mb-2">
        <svg
          class="inline-block mr-2"
          id="icon-checksquare"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <path
            id="check-square"
            d="M14.286,32H1.714A1.714,1.714,0,0,0,0,33.714V46.286A1.714,1.714,0,0,0,1.714,48H14.286A1.714,1.714,0,0,0,16,46.286V33.714A1.714,1.714,0,0,0,14.286,32Zm0,14.286H1.714V33.714H14.286ZM13,37.653,6.841,43.767a.429.429,0,0,1-.606,0L2.993,40.5a.429.429,0,0,1,0-.606l.811-.8a.429.429,0,0,1,.606,0l2.135,2.153L11.6,36.233a.429.429,0,0,1,.606,0l.8.811a.429.429,0,0,1,0,.606Z"
            transform="translate(0 -32)"
          />
        </svg>
        <div class="thirdhead-text inline-block mb-4">Settings</div>
      </div>
      <div class="report_box grid gap-8 grid-cols-3">
        <div>
          <router-link v-if="!isSra()" to="/admin/sysadmin/users">
            <button
              class="button bg-white border border-cool-gray w-5/6 rounded-md py-2 mb-4"
            >
              Users
            </button>
          </router-link>
        </div>
        <div>
          <router-link v-if="!isSra()" to="/admin/sysadmin/milestones">
            <button
              class="button bg-white border border-cool-gray w-5/6 rounded-md py-2 mb-4"
            >
              Milestones / School Year
            </button>
          </router-link>
        </div>
        <div>
          <router-link v-if="!isSra()" to="/admin/sysadmin/banner">
            <button
              class="button bg-white border border-cool-gray w-5/6 rounded-md py-2 mb-4"
            >
              Banner
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isSra } from "@/composables/tokenUtils";
export default {
  methods: {
    isSra,
  },
  name: "SysAdmin",
};
</script>

<style scoped></style>
